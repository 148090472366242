<template>
  <div class="d-flex switch">
    <div
      class="btn mr-2"
      :class="listingType === 'R' ? 'btn-primary' : 'btn-primary--outline'"
      @click="changeType('R')"
    >
      {{ isHomepage ? $t('general.rent') : $t('general.filter.R') }}
    </div>
    <div
      class="btn"
      :class="listingType === 'S' ? 'btn-primary' : 'btn-primary--outline'"
      @click="changeType('S')"
    >
      {{ isHomepage ? $t('general.sell') : $t('general.filter.S') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'rent-sell-switch',
  props: ['listingType', 'isHomepage'],
  methods: {
    changeType(newType) {
      this.$emit('changeType', newType);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.btn {
  width: 128px;
  height: 56px;
  border-radius: 16px;
  font-size: $font-ml;

  @media #{$phones} {
    width: 90px;
    height: 40px;
    font-size: $font-sm;
  }
}

.btn-primary--outline {
  background-color: transparent;
}
</style>
